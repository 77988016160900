<script setup lang="ts">
    import { useNuxtApp } from 'nuxt/app';
    import { useAppStore } from '~/store';
    import { ScrollDir } from 'plugins/scroll';

    const appStore = useAppStore();
    const nuxtApp = useNuxtApp();

    if (process.client) {
        appStore.actions.checkWebsite();
    }

    const menu = computed(() => {
        if (appStore.state && appStore.state.menu) {
            return appStore.state.menu;
        }
        return null;
    });

    const country = computed(() => {
        if (appStore.state && appStore.state.country) {
            return appStore.state.country;
        }
        return '';
    });

    const storeLanguage = computed(() => {
        if (appStore.state && appStore.state.lang) {
            return appStore.state.lang;
        }
        return '';
    });

    const scroll = ref(0);
    const scrollDir = ref<ScrollDir>('up');
    const showSystem = ref(true);
    const specialTheme = ref('');

    const locale = computed(() => appStore.getters.pageLocale());
    /**
       * TODO: getAllColors data missing in appStore.getters
       *
       *  ...mapGetters({
          allColors: 'app/getAllColors',
          locale: 'app/pageLocale',
          }),
       */

    const layoutClass = computed(() => {
        return scrollDir.value === 'up' || scroll.value < 100 ? 'scroll-up' : 'scroll-down';
    });

    const menuVisible = computed({
        get: () => {
            return menu.value;
        },
        set: (val) => {
            console.log('menuVisible ~ val', val);

            // $store.commit('app/toggleMenu', val)
        },
    });

    const closeSystemBar = () => {
        showSystem.value = false;
    };

    const handleScroll = () => {
        // update the scroll event bus
        if (window && document) {
            /**
             * window.pageYOffset
             * 'pageYOffset' is deprecated.
             */

            const scrollPos = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
            let scrollDirection = 'up' as ScrollDir;
            if (scrollPos > scroll.value) {
                scrollDirection = 'down';
            }
            scroll.value = scrollPos;
            scrollDir.value = scrollDirection;
            nuxtApp.$hbScroller.updateScroll(scrollPos, scrollDirection);
        }
    };

    onBeforeMount(() => {
        window.addEventListener('scroll', handleScroll, { capture: true, passive: true });
        handleScroll();
    });
    onBeforeUnmount(() => {
        if (window) {
            window.removeEventListener('scroll', handleScroll, true);
        }
    });

    onMounted(() => {
        const res = document.getElementsByTagName('html');
        // only allow trusted shops on client
        // if (process.client && trustedShopId) {
        //   trustedShopsPlugin(trustedShopId)
        // }

        if (document && specialTheme.value !== '') {
            const body = document.querySelector('body');
            if (body) {
                body.setAttribute('woom-theme', specialTheme.value);
            }
        }
    });
</script>

<template>
    <div class="woom-app woom-theme">
        <!-- <mol-menu :visible.sync="menuVisible" /> TODO: -->
        <!-- <mol-menu></mol-menu> -->
        <!-- client-only>
            <lazy-language-drawer></lazy-language-drawer>
        </client-only -->
        <mol-header></mol-header>
        <nuxt-page></nuxt-page>
        <mol-footer></mol-footer>
    </div>
</template>

<!-- <template>
    
  <v-app class="woom-app woom-theme" :class="specialTheme">
    <mol-menu :visible.sync="menuVisible" />
    <woom-script-references />
    <client-only>
      <lazy-cart-drawer />
      <lazy-language-drawer />
      <lazy-content-drawer />
      <lazy-woom-form-drawer />
      <lazy-video-modal />
      <lazy-product-images-fullscreen />
      <woom-snackbar-container />
      <user-entry />
    </client-only>
    <mol-header />
    <nuxt />
    <mol-footer />
    <div
      :key="trustedShopId"
      v-if="!!trustedShopId"
      id="trustedShopBadgeWoom"
      :class="{ showTrustedShopBadgeWoom: scroll == 0 }"
      class="trusted-badge"
      style="width: 160px; height: 80px"
    />
  </v-app>
</template> -->

<style>
    .widget {
        max-width: 100vw !important;
    }
    .widget:not(#dr_cookie_banner_container[class*='cc-invisible']) + #__nuxt {
        opacity: 0.2;
    }
    .woom-app {
        max-width: 100vw;
    }

    #trustedShopBadgeWoom {
        position: fixed;
        top: calc(100vh - 78px - 16px);
        left: 16px;
        z-index: 2;
        transition: opacity 0.4s ease-out;
        opacity: 0;
    }

    #trustedShopBadgeWoom .showTrustedShopBadgeWoom {
        opacity: 1;
    }

    /* this is a hack because trusted shop is ignoring all configurations if there is no single review */
    #trustedShopBadgeWoom .showTrustedShopBadgeWoom ._15ma014 {
        left: 16px !important;
    }
    .pink-bike {
        --color-primary: #f372a6;
        --color-red: #f372a6;
        --logo-color: #f372a6;
    }

    .pink-bike .woom-notifications {
        background-color: #f372a6 !important;
        color: white !important;
        --color: white;
        --text-color-light-grey: white;
    }

    /* TODO: update from old site */
    .pink-bike .woom-notifications .woom-notification,
    p {
        /* color: white !important; */
    }
    body[woom-theme='pink-bike'] .cc-banner .cc-allow,
    body[woom-theme='pink-bike'] .cc-banner .cc-allow:focus {
        background-color: #f372a6;
    }

    body[woom-theme='pink-bike'] .cc-banner .cc-allow:hover {
        color: white !important;
    }

    body[woom-theme='pink-bike'] .cc-banner .dr-headline {
        color: #f372a6 !important;
    }
    body[woom-theme='pink-bike'] .cc-banner li.dr-category-headline {
        color: #f372a6 !important;
    }
    body[woom-theme='pink-bike'] .cc-banner .dr-tab-category-morelink {
        color: #f372a6 !important;
    }
    body[woom-theme='pink-bike'] .cc-banner #dr-hiddencat-btn-apply {
        background-color: #f372a6 !important;
        color: white !important;
    }
    body[woom-theme='pink-bike'] .cc-banner #dr-hiddencat-btn-apply:hover {
        color: white !important;
    }
    body[woom-theme='pink-bike'] .cc-revoke.cc-bottom {
        background-color: #f372a6 !important;
        color: white !important;
    }
    body[woom-theme='pink-bike'] .cc-revoke.cc-bottom:hover {
        color: white !important;
    }
</style>
